import ButtonDefault from '@mui/material/Button';
import { Link } from "react-router-dom";

type ButtonProps = {
  label?: string;
  variant?: 'contained' | 'outlined' | 'text';
  type?: 'submit' | 'button';
  path?: string;
  loading?: boolean;
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large';
  href?:  string;
}

export function Button(props: ButtonProps) {
  const { label = 'button', variant = 'outlined', size = 'large', path, type, loading, onClick, href } = props

  const sentenceCase = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  if (!!path) {
    return <Link to={path} style={{ textDecoration: "none" }}><ButtonDefault
      disabled={loading}
      type={type}
      variant={variant}
      size={size}>
      {sentenceCase(label)}
    </ButtonDefault></Link>
  } else return (<ButtonDefault
      disabled={loading}
      type={type}
      variant={variant}
      onClick={onClick}
      href={href}
      size={size}>
      {sentenceCase(label)}
    </ButtonDefault>
  )
}

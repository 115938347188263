// material
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Stack, Typography } from '@mui/material';

// components
import Page from 'components/Page';
import { MHidden } from 'components/@material-extend';
import Logo from "components/Logo";
import { LoginForm } from './components/LoginForm';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(() => ({
  width: '100%',
  maxWidth: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  borderRadius: 0,
  backgroundColor: '#42264D'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function Login() {
  return (
    <RootStyle title="Bloom | Login">
      <MHidden width="mdDown">
        <SectionStyle>
          <Logo schema={'white'} style={{ width: '25%', height: '100%', marginBottom: '100px', marginLeft: '100px' }}/>
        </SectionStyle>
      </MHidden>
      <Container>
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Plataforma de gestão
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Faça o login abaixo</Typography>
            </Box>
          </Stack>
          <LoginForm/>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

export const BACKOFFICE_EXCEPTION = {
  remote_address_blocked : 'IP bloqueado por 5 minutos',
  username_blocked : 'Usuário bloqueado por 5 minutos',
  username_password_invalid : 'Usuário ou senha inválido',
  company_name_already_exists : 'Já existe uma empresa com o esse nome cadastrado',
  company_document_already_exists : 'Empresa já cadastrada',
  company_name_document_missing : 'Empresa não localizada',
  phase_name_already_exists : 'Já existe uma fase com o esse nome cadastrado',
  email_already_exists : 'Email já cadastrado',
  subscriber_already_exists : 'Assinante já cadastrado(a)',
  contract_not_found : 'Não foi localizado nenhum contrato ativo',
  notification_profiles_not_found : 'Não foi encontrado nenhum assinante para essa segmentação',
  media_file_not_found : 'Erro ao procesar upload do arquivo',
  specialist_document_already_exists : 'Especialista já cadastrado(a)',
  doctor_document_already_exists : 'Médico(a) já cadastrado(a)',
  nurse_document_already_exists : 'Enfermeiro(a) já cadastrado(a)',
  health_professional_document_already_exists : 'Profissional de saúde já cadastrado(a)',
  admin_email_already_exists : 'Usuário já cadastrado',
  theme_name_already_exists : 'Já existe um tema com o esse nome cadastrado',
  import_media_file_not_found: 'Mídia não encontrada',
  import_mapping_excel_file: 'Erro no mapeamento do arquivo excel',
  import_invalid_excel_file: 'Arquivo excel inválido',
  import_select_company_to_import: 'Selecione uma empresa para a importação',
  import_firstName_email_gender_and_birthdate_must_be_filled: 'Nome, e-mail, sexo e data de nascimento são de preenchimento obrigatório',
  there_are_identical_emails_in_the_spreadsheet: 'Existe e-mails iguais na planilha de importação',
  invalid_phone_number: 'Número de telefone inválido',
  'PhoneNumbers.PhoneNumber is and invalid phone number': 'Número de telefone inválido',
  'Padding is invalid and cannot be removed.': 'Nenhuma página encontrada.',
  content_not_found: 'Conteúdo não foi localizado'
}
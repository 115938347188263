import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
// type
import { UploadProps } from './type';
//
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import React from "react";
import { NoEncryption } from '@mui/icons-material';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------


export function UploadSingleFile(
  {
    error = false,
    file,
    helperText = '',
    sx,
    accept,
    acceptFormat,
    ...other
  }: UploadProps) {

  function message(file: any) {
    if (!file.type.includes(acceptFormat)) {
      return {
        message: "Formato do arquivo inválido",
        code: 'custom',
      }
    }
    return null
  }

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    accept,
    validator: message,
    ...other

  });
  
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          border: helperText ? 'solid 1px #FF4842' : 'none',
        }}
      >
        <input {...getInputProps()} />
        {(!file || acceptFormat !== "image") && (<BlockContent accept={accept} />)}
        {file && acceptFormat === "image" && (
          <Image
            alt="file preview"
            src={isString(file) ? file ?? undefined : file.preview ?? undefined}
            sx={{
              top: 8,
              left: 8,
              borderRadius: 1,
              width: '100%',
            }}
          />
        )}
      </DropZoneStyle>
      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
      {!!helperText && <Typography variant='overline' >{helperText}</Typography>}
      {file && acceptFormat === "audio" && (<audio controls style={{ width: '100%', marginTop: '16px' }}>
        <source src={isString(file) ? file ?? undefined : file.preview ?? undefined} type="audio/mpeg" />
      </audio>
      )}
      {file && acceptFormat === "video" && (<video controls style={{ width: '100%', borderRadius: '5px', marginTop: '16px' }}>
        <source src={isString(file) ? file ?? undefined : file.preview ?? undefined} type="audio/mpeg" />
      </video>
      )}
    </Box>
  );
}

import { backofficeApiConfig } from "config";
import { useSnackbar } from "notistack";
import { Api, MediaModelResultHandler, MediaUploadCreateParams, ProfileExportListParams, ReportCriteria } from "./BackofficeApi";
import { BACKOFFICE_EXCEPTION } from "./ExceptionDictionary";
import FileSaver from "file-saver";
import { v4 as uuid } from 'uuid';

export const useBackofficeApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const accessToken = window.localStorage.getItem('accessToken');

  const api = new Api({
    baseURL: backofficeApiConfig.backofficeApiURL,
    headers: { "Authorization": `bearer ${accessToken}` }
  });

  api.instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {

    let errorMessage = error?.response?.data?.message ?? "Erro ao processar a solicitação";
    if (BACKOFFICE_EXCEPTION.hasOwnProperty(errorMessage)) {
      // @ts-ignore
      errorMessage = BACKOFFICE_EXCEPTION[errorMessage];
    }
    enqueueSnackbar(errorMessage, { variant: "error" });
    return Promise.reject(error);
  });

  // @ts-ignore
  api.api.mediaUploadCreate = (
    query: MediaUploadCreateParams,
    data: FormData,
    onUploadProgress: (progress: number) => void
  ) => {
    return api.request<MediaModelResultHandler, any>({
      path: `/api/media/upload`,
      method: 'POST',
      query: query,
      body: data,
      onUploadProgress: (data) => {
        onUploadProgress((data.loaded * 100 / data.total));
      }
    },)
  };

  // @ts-ignore
  api.api.profileExportList = async (
    query: ProfileExportListParams
  ) => {
    await api.instance.request({
      url: backofficeApiConfig.backofficeApiURL + `/api/profile/export`,
      params: { ...query },
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(new Blob([response.data]), uuid() + '.xlsx');
    });
  };

  // @ts-ignore
  api.api.dashboardContentMoreAccessExportCreate = async (
    data: ReportCriteria
  ) => {
    await api.instance.request({ 
      url: backofficeApiConfig.backofficeApiURL + `/api/dashboard/content/more-access/export`,
      method: 'POST',
      responseType: 'blob',
      data: data
    }).then((response) => {
      FileSaver.saveAs(new Blob([response.data]), uuid() + '.xlsx');
    });
  };

  return api;
}
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

// material
import { Alert, Stack, } from '@mui/material';

// components
import { TextInput } from "components/TextInput";

// services
import { Button } from "components/Button";
import { useState } from "react";
import useAuth from "../../../hooks/useAuth";

type LoginFormValues = {
  email: string;
  password: string;
};

export function LoginForm() {
  const { login } = useAuth();

  const [loginError, setLoginError] = useState<boolean>(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("E-mail inválido").required("E-mail inválido"),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setLoginError(false);
      await login(values.email, values.password).catch(() => {
        setLoginError(true);
      });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, handleChange, getFieldProps, values } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {loginError && <Alert severity="error">Usuário ou senha inválido</Alert>}
          <TextInput
            autoComplete="username"
            mask="username"
            label="Email"
            value={values.email}
            onChange={handleChange('email')}
            error={Boolean(touched.email && errors.email)}
          />
          <TextInput
            secureTextEntry
            label="Senha"
            value={values.password}
            onChange={handleChange('password')}
            error={Boolean(touched.password && errors.password)}
          />
          <Button label={"Entrar"} type={"submit"} loading={isSubmitting}/>
        </Stack>
        {/*<Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>*/}
        {/*  <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.auth.forgotPassword}>*/}
        {/*    Esqueceu sua senha?*/}
        {/*  </Link>*/}
        {/*</Stack>*/}
      </Form>
    </FormikProvider>
  );
}

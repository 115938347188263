import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

// material
import { Card, Grid, Stack, Typography } from '@mui/material';

// components
import { Upload } from 'components/Upload';
import { Switch } from 'components/Switch';
import { RadioInput } from 'components/RadioInput';
import Autocomplete from 'components/Autocomplete';
import { TextInput } from 'components/TextInput';
import Editor from 'components/Editor';

// types
import { KeyValue } from 'types/KeyValue';

// utils
import mappingEnumToKeyValue, { mEnumToValue } from 'utils/mapping';
import sort from 'utils/sort';

// apis
import { useBackofficeApi } from 'apis/backoffice';
import {
  ContentStatusEnum,
  ContentTypeEnum,
  MediaTypeEnum,
  SpecialistDetailModel,
} from 'apis/backoffice/BackofficeApi';
import { Button } from 'components/Button';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { RenderFile } from '../../../components/Upload/type';
import { PATH_COLLECTIONS } from '../../../routes/paths';

type ContentFormProps = {
  contentId?: string;
  readonly?: boolean;
  mindetail?: boolean;
};
type radioType = 'Audio' | 'Video' | 'Article';
const defaultRadioKey: radioType = 'Audio';
type FormValues = {
  type: KeyValue;
  radioKey: radioType;
  status: ContentStatusEnum;
  title: string;
  headline: string;
  description: string;
  transcription: string;
  theme?: KeyValue;
  specialist?: KeyValue;
  phases?: KeyValue[];
  personas?: KeyValue[];
  audioFile?: RenderFile;
  videoFile?: RenderFile;
  coverFile?: RenderFile;
  highlightFile?: RenderFile;
};

const DefaultValue = {
  type: { key: ContentTypeEnum.Audio, value: ContentTypeEnum.Audio },
  radioKey: defaultRadioKey,
  status: ContentStatusEnum.Draft,
  title: '',
  headline: '',
  description: '',
  transcription: '',
  theme: undefined,
  specialist: undefined,
  phases: [],
  personas: [],
  audioFile: undefined,
  videoFile: undefined,
  coverFile: undefined,
  highlightFile: undefined,
};

export function ContentForm({ mindetail, contentId, readonly = false }: ContentFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const backofficeApi = useBackofficeApi();

  const [loading, setLoading] = useState<boolean>(false);

  const [types, setTypes] = useState<KeyValue[]>([]);
  const [personas, setPersonas] = useState<KeyValue[]>([]);
  const [themes, setThemes] = useState<KeyValue[]>([]);
  const [phases, setPhases] = useState<KeyValue[]>([]);
  const [specialists, setSpecialists] = useState<KeyValue[]>([]);
  const [specialist, setSpecialist] = useState<SpecialistDetailModel>();

  const [content, setContent] = useState<FormValues>(DefaultValue);

  const fetchContent = async () => {
    !!contentId &&
      (await backofficeApi.api.contentDetail(contentId).then((resp) => {
        const contentDetail = resp.data.data;
        setContent({
          type: { key: contentDetail?.type, value: mEnumToValue(contentDetail?.type) },
          radioKey: contentDetail?.type ?? defaultRadioKey,
          status: contentDetail?.status ?? ContentStatusEnum.Draft,
          title: contentDetail?.title ?? '',
          headline: contentDetail?.headline ?? '',
          description: contentDetail?.description ?? '',
          transcription: contentDetail?.transcription ?? '',
          theme: contentDetail?.theme?.key
            ? { key: contentDetail?.theme?.key, value: contentDetail?.theme?.value }
            : undefined,
          specialist: {
            key: contentDetail?.specialist?.key,
            value: contentDetail?.specialist?.value,
          },
          phases: contentDetail?.phases?.map((a) => ({ key: a?.key, value: a?.value })),
          personas: contentDetail?.personas?.map((a) => ({ key: a?.key, value: a?.value })),
          audioFile: contentDetail?.audioMedia
            ? { preview: contentDetail.audioMedia.url, mediaId: contentDetail.audioMedia.mediaId }
            : undefined,
          videoFile: contentDetail?.videoMedia
            ? { preview: contentDetail.videoMedia.url, mediaId: contentDetail.videoMedia.mediaId }
            : undefined,
          highlightFile: contentDetail?.highlightMedia
            ? {
                preview: contentDetail.highlightMedia.url,
                mediaId: contentDetail.highlightMedia.mediaId,
              }
            : undefined,
          coverFile: contentDetail?.coverMedia
            ? { preview: contentDetail.coverMedia.url, mediaId: contentDetail.coverMedia.mediaId }
            : undefined,
        });

        if (
          contentDetail?.specialist?.key !== null &&
          contentDetail?.specialist?.key !== undefined &&
          mindetail
        ) {
          backofficeApi.api.publicSpecialistDetail(contentDetail?.specialist?.key).then((resp) => {
            setSpecialist(resp.data.data);
          });
        }
      }));
  };

  const fetchTypes = async () => {
    await backofficeApi.api.contentTypeList().then((resp) => {
      setTypes(mappingEnumToKeyValue(resp.data.data));
    });
  };

  const fetchPersonas = async () => {
    await backofficeApi.api.personaAutocompleteList({}).then((resp) => {
      setPersonas(sort(resp.data.data ?? []));
    });
  };

  const fetchThemes = async () => {
    await backofficeApi.api.themeAutocompleteList({}).then((resp) => {
      setThemes(sort(resp.data.data ?? []));
    });
  };

  const fetchPhases = async () => {
    await backofficeApi.api.phaseAutocompleteList({}).then((resp) => {
      setPhases(sort(resp.data.data ?? []));
    });
  };

  const fetchSpecialists = async () => {
    await backofficeApi.api.specialistAutocompleteList({}).then((resp) => {
      setSpecialists(sort(resp.data.data ?? []));
    });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      !readonly &&
        (await Promise.all([
          fetchTypes(),
          fetchPersonas(),
          fetchThemes(),
          fetchPhases(),
          fetchSpecialists(),
        ]));
      await fetchContent();
      setLoading(false);
    })();
  }, []);

  const schema = Yup.object().shape({
    title: Yup.string().required('Campo de título é obrigatório'),
    // headline: Yup.string().required('Campo de título destaque é obrigatório'),
    description: Yup.string().when('radioKey', {
      is: 'Article',
      then: Yup.string()
        .min(10, 'Campo de descrição muito curta')
        .required('Campo de descrição é obrigatório'),
    }),
    audioFile: Yup.object().when('radioKey', {
      is: 'Audio',
      then: Yup.object().required('Campo de áudio é obrigatório'),
    }),
    videoFile: Yup.object().when('radioKey', {
      is: 'Video',
      then: Yup.object().required('Campo de vídeo é obrigatório'),
    }),
    /*
    headline: Yup.string().required('Titulo destaque obrigatório'),
    theme: Yup.object().required(`Tema obrigatório`),
    phases: Yup.array().of(Yup.object().required(`defaultRadioKey`)).min(2, `messageHere`),
    specialist: Yup.object().required(`Especialista obrigatório`),
    personas: Yup.array().min(2, `messageHere`),
    transcription: Yup.string().min(10, 'Transcrição muito curta').required('Transcrição obrigatóris'), 
    */
  });

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: content,
    validationSchema: schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      const payload = {
        status: values.status,
        type: values.type.key as ContentTypeEnum,
        title: values.title,
        headline: values.headline,
        description: values.description,
        transcription: values.transcription,
        themeId: values.theme?.key,
        specialistId: values.specialist?.key,
        phaseIds: values.phases?.map((a) => a.key ?? ''),
        personaIds: values.personas?.map((a) => a.key ?? ''),
        audioMediaId: values.audioFile?.mediaId,
        videoMediaId: values.videoFile?.mediaId,
        highlightMediaId: values.highlightFile?.mediaId,
        coverMediaId: values.coverFile?.mediaId,
      };

      if (!contentId)
        await backofficeApi.api
          .contentCreate({
            ...payload,
          })
          .then((resp) => {
            navigate(PATH_COLLECTIONS.collections.contents.list);
            enqueueSnackbar('Conteúdo salvo com sucesso!', { variant: 'success' });
          });
      else {
        await backofficeApi.api
          .contentUpdate({
            contentId: contentId,
            ...payload,
          })
          .then((resp) => {
            navigate(PATH_COLLECTIONS.collections.contents.list);
            enqueueSnackbar('Conteúdo atualizado com sucesso!', { variant: 'success' });
          });
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, setFieldValue, values } = formik;

  return (
    <>
    { mindetail &&
      <Grid style={{ backgroundColor: '#42264D', height: '80px', width: '100%' }}>
        <Typography
          variant="h5"
          style={{ color: '#ffffff', textAlign: 'center', lineHeight: '80px' }}
        >
          {values.theme?.value}
        </Typography>
      </Grid>
    }
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Card sx={{ mb: 3, p: 3 }}>
            {mindetail ? (
              <Stack spacing={3}>
                <Grid container>
                  <Grid xs={12} sm={12} md={4} style={{ padding: '20px' }} item>
                    {values.highlightFile ? (
                      <Upload
                        accept={'image'}
                        mediaType={MediaTypeEnum.ImageHighlight}
                        readonly={readonly}
                        loading={loading}
                        onFileChange={(val) => setFieldValue('highlightFile', val)}
                        file={values.highlightFile}
                      />
                    ) : (
                      <Grid
                        style={{
                          border: '1px #505050 solid',
                          borderRadius: '10px',
                          height: '300px',
                        }}
                      >
                        <p
                          style={{ display: 'flex', justifyContent: 'center', marginTop: '140px' }}
                        >
                          Sem foto de destaque...
                        </p>
                      </Grid>
                    )}
                  </Grid>

                  <Grid xs={12} sm={12} md={8} style={{ padding: '20px' }} item>
                    <Grid sm={12} md={12} item>
                      <Typography variant="subtitle2" sx={{ mb: 3 }} style={{ color: '#d9a8a3' }}>
                        {values.type.value}
                      </Typography>
                    </Grid>
                    <Grid sm={12} md={12} item>
                      <Typography variant="h5" sx={{ mb: 3 }}>
                        {values.title}
                      </Typography>
                    </Grid>


                    {specialist ? (
                      <Grid xs={12} sm={12} md={12} sx={{ mt: 3 }} item>
                        <div
                          style={{
                            width: '60px',
                            height: '60px',
                            backgroundImage: `url(${specialist?.avatarMedia?.url})`,
                            backgroundSize: 'cover',
                            borderRadius: '50%',
                            float: 'left',
                            boxShadow: '1px 2px 5px #505050',
                            marginRight: '20px',
                          }}
                        ></div>
                        <Typography variant="h6" sx={{ ml: 2 }}>
                          {`${
                            specialist?.gender == 'Male' ? 'Dr.' : 'Dra.'
                          } ${specialist?.firstName?.trim()} ${specialist?.lastName?.trim()}`}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ ml: 2, mb: 3 }}>
                          {specialist?.specialization?.value}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid
                        style={{
                          border: '1px #505050 solid',
                          borderRadius: '10px',
                          marginTop: '30px',
                        }}
                      >
                        <p style={{ display: 'flex', justifyContent: 'center' }}>
                          {`Especialista não encontrado(a)...`}
                        </p>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid sm={12} md={12} sx={{ mb: 2 }} item>
                  {(values.type.key as ContentTypeEnum) === ContentTypeEnum.Video &&
                    values.videoFile !== undefined && (
                      <Upload
                        label={'Vídeo'}
                        accept={'video'}
                        mediaType={MediaTypeEnum.Video}
                        readonly={readonly}
                        loading={loading}
                        required
                        helperText={
                          Boolean(touched.videoFile && errors.videoFile) &&
                          touched.videoFile &&
                          errors.videoFile
                        }
                        onFileChange={(val) => setFieldValue('videoFile', val)}
                        file={values.videoFile}
                      />
                    )}
                  {(values.type.key as ContentTypeEnum) === ContentTypeEnum.Video &&
                    values.videoFile === undefined && (
                      <Grid style={{ border: '1px #505050 solid', borderRadius: '10px' }}>
                        <p style={{ display: 'flex', justifyContent: 'center' }}>
                          {`${values.type.value} não encontrado...`}
                        </p>
                      </Grid>
                    )}

                  {(values.type.key as ContentTypeEnum) === ContentTypeEnum.Audio &&
                    values.audioFile !== undefined && (
                      <Upload
                        label={'Áudio'}
                        accept={'audio'}
                        mediaType={MediaTypeEnum.Audio}
                        readonly={readonly}
                        loading={loading}
                        required
                        helperText={
                          Boolean(touched.audioFile && errors.audioFile) &&
                          touched.audioFile &&
                          errors.audioFile
                        }
                        onFileChange={(val) => setFieldValue('audioFile', val)}
                        file={values.audioFile}
                      />
                    )}

                  {(values.type.key as ContentTypeEnum) === ContentTypeEnum.Audio &&
                    values.audioFile === undefined && (
                      <Grid style={{ border: '1px #505050 solid', borderRadius: '10px' }}>
                        <p style={{ display: 'flex', justifyContent: 'center' }}>
                          {`${values.type.value} não encontrado...`}
                        </p>
                      </Grid>
                    )}
                </Grid>
                <Grid sm={12} md={12} sx={{ mb: 2 }}>
                  {(values.type.key as ContentTypeEnum) !== ContentTypeEnum.Article &&
                    values.transcription !== '' && (
                      <Editor
                        error={Boolean(touched.transcription && errors.transcription)}
                        readonly={readonly}
                        loading={loading}
                        id={'editor-transcription'}
                        label={'Transcrição'}
                        value={values.transcription}
                        onChange={(val) => setFieldValue('transcription', val)}
                      />
                    )}

                  {(values.type.key as ContentTypeEnum) === ContentTypeEnum.Video &&
                    values.transcription === '' && (
                      <Grid style={{ border: '1px #505050 solid', borderRadius: '10px' }}>
                        <p style={{ display: 'flex', justifyContent: 'center' }}>
                          {`Transcrição não encontrada...`}
                        </p>
                      </Grid>
                    )}
                  {(values.type.key as ContentTypeEnum) === ContentTypeEnum.Audio &&
                    values.transcription === '' && (
                      <Grid style={{ border: '1px #505050 solid', borderRadius: '10px' }}>
                        <p style={{ display: 'flex', justifyContent: 'center' }}>
                          {`Transcrição não encontrada...`}
                        </p>
                      </Grid>
                    )}

                  {(values.type.key as ContentTypeEnum) === ContentTypeEnum.Article &&
                    values.description !== '' && (
                      <Editor
                        error={Boolean(touched.description && errors.description)}
                        readonly={readonly}
                        loading={loading}
                        id={'editor-description'}
                        label={'Descrição'}
                        required={
                          !readonly &&
                          (values.type.key as ContentTypeEnum) === ContentTypeEnum.Article
                        }
                        helperText={
                          Boolean(touched.description && errors.description) &&
                          touched.description &&
                          errors.description
                        }
                        value={values.description}
                        onChange={(val) => setFieldValue('description', val)}
                      />
                    )}
                  {(values.type.key as ContentTypeEnum) === ContentTypeEnum.Article &&
                    values.description === '' && (
                      <Grid style={{ border: '1px #505050 solid', borderRadius: '10px' }}>
                        <p style={{ display: 'flex', justifyContent: 'center' }}>
                          {`Descrição não encontrada...`}
                        </p>
                      </Grid>
                    )}
                </Grid>
              </Stack>
            ) : (
              <Stack spacing={3}>
                <RadioInput
                  label="Tipo"
                  loading={loading}
                  readonly={readonly}
                  values={types ?? []}
                  selected={values.type}
                  onChange={(val) => {
                    setFieldValue('type', val as ContentTypeEnum);
                    setFieldValue('radioKey', val?.key);
                  }}
                />
                <TextInput
                  label="Título"
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                  readonly={readonly}
                  loading={loading}
                  value={values.title}
                  maxLength={50}
                  required
                  onChangeValue={(val) => setFieldValue('title', val)}
                />
                <TextInput
                  readonly={readonly}
                  loading={loading}
                  error={Boolean(touched.headline && errors.headline)}
                  helperText={touched.headline && errors.headline}
                  label="Título destaque"
                  value={values.headline}
                  onChangeValue={(val) => setFieldValue('headline', val)}
                />
                <Autocomplete
                  readonly={readonly}
                  loading={loading}
                  label="Tema"
                  options={themes ?? []}
                  value={values.theme}
                  onChange={(val) => setFieldValue('theme', val)}
                />
                <Autocomplete
                  readonly={readonly}
                  loading={loading}
                  label="Fase"
                  multiple
                  options={phases ?? []}
                  values={values.phases}
                  onChange={(val) => setFieldValue('phases', val)}
                  error={Boolean(touched.phases && errors.phases)}
                  helperText={touched.phases && errors.phases}
                />
                <Autocomplete
                  error={Boolean(touched.specialist && errors.specialist)}
                  helperText={touched.specialist && errors.specialist}
                  readonly={readonly}
                  loading={loading}
                  label="Especialista"
                  options={specialists ?? []}
                  value={values.specialist}
                  onChange={(val) => setFieldValue('specialist', val)}
                />
                <Autocomplete
                  label="Tags/Personas"
                  readonly={readonly}
                  loading={loading}
                  multiple
                  options={personas ?? []}
                  values={values.personas}
                  onChange={(val) => setFieldValue('personas', val)}
                />
                {(values.type.key as ContentTypeEnum) === ContentTypeEnum.Audio && (
                  <Upload
                    label={'Áudio'}
                    accept={'audio'}
                    mediaType={MediaTypeEnum.Audio}
                    readonly={readonly}
                    loading={loading}
                    required
                    helperText={
                      Boolean(touched.audioFile && errors.audioFile) &&
                      touched.audioFile &&
                      errors.audioFile
                    }
                    onFileChange={(val) => setFieldValue('audioFile', val)}
                    file={values.audioFile}
                  />
                )}
                {(values.type.key as ContentTypeEnum) === ContentTypeEnum.Video && (
                  <Upload
                    label={'Vídeo'}
                    accept={'video'}
                    mediaType={MediaTypeEnum.Video}
                    readonly={readonly}
                    loading={loading}
                    required
                    helperText={
                      Boolean(touched.videoFile && errors.videoFile) &&
                      touched.videoFile &&
                      errors.videoFile
                    }
                    onFileChange={(val) => setFieldValue('videoFile', val)}
                    file={values.videoFile}
                  />
                )}
                <Editor
                  error={Boolean(touched.description && errors.description)}
                  readonly={readonly}
                  loading={loading}
                  id={'editor-description'}
                  label={'Descrição'}
                  required={
                    !readonly && (values.type.key as ContentTypeEnum) === ContentTypeEnum.Article
                  }
                  helperText={
                    Boolean(touched.description && errors.description) &&
                    touched.description &&
                    errors.description
                  }
                  value={values.description}
                  onChange={(val) => setFieldValue('description', val)}
                />
                {(values.type.key as ContentTypeEnum) !== ContentTypeEnum.Article && (
                  <Editor
                    error={Boolean(touched.transcription && errors.transcription)}
                    readonly={readonly}
                    loading={loading}
                    id={'editor-transcription'}
                    label={'Transcrição'}
                    value={values.transcription}
                    onChange={(val) => setFieldValue('transcription', val)}
                  />
                )}
                {(values.type.key as ContentTypeEnum) !== ContentTypeEnum.Video && (
                  <Upload
                    label={'Foto da capa (Abertura do conteúdo)'}
                    accept={'image'}
                    mediaType={MediaTypeEnum.ImageCover}
                    readonly={readonly}
                    loading={loading}
                    onFileChange={(val) => setFieldValue('coverFile', val)}
                    file={values.coverFile}
                  />
                )}
                <Upload
                  label={'Foto de destaque'}
                  accept={'image'}
                  mediaType={MediaTypeEnum.ImageHighlight}
                  readonly={readonly}
                  loading={loading}
                  onFileChange={(val) => setFieldValue('highlightFile', val)}
                  file={values.highlightFile}
                />
                <Switch
                  disabled={readonly}
                  label={'Publicado'}
                  value={values.status === ContentStatusEnum.Published}
                  onChange={(val) =>
                    setFieldValue(
                      'status',
                      val ? ContentStatusEnum.Published : ContentStatusEnum.Draft
                    )
                  }
                />
              </Stack>
            )}
          </Card>
          <Stack alignItems="flex-end">
            {!readonly && (
              <Button
                type="submit"
                loading={isSubmitting}
                label={!!contentId ? 'Salvar' : 'Cadastrar'}
              />
            )}
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_DEEPLINKING = '/deeplinking';
const ROOTS_COLLECTIONS = '/collections';
const ROOTS_CLIENTS = '/clients';
const ROOTS_ATTENDANCES = '/attendances';
const ROOTS_USERS = '/users';
const ROOTS_AUTH = '/auth';
const ROOTS_NOTIFICATION = '/notifications';
const ROOTS_PUBLIC = '/';

export const PATH_DASHBOARD = {
  root: '/',
  panel: {
    dashboard: path(ROOTS_DASHBOARD, '/')
  }
}

export const PATH_DEEPLINKING = {
  root: '/',
  panel: {
    deeplinking: path(ROOTS_DEEPLINKING, '/')
  }
}

export const PATH_PUBLIC = {
  root: '/',
  public: {
    contents: {
      detail: path(ROOTS_PUBLIC, '/:contentId/:titleFmt')
    }
  }
};

export const PATH_COLLECTIONS = {
  root: '/',
  collections: {
    contents: {
      list: path(ROOTS_COLLECTIONS, '/contents'),
      create: path(ROOTS_COLLECTIONS, '/contents/create'),
      detail: path(ROOTS_COLLECTIONS, '/contents/:contentId'),
      mindetail: path(ROOTS_COLLECTIONS, '/contents/:contentId/min'),
      edit: path(ROOTS_COLLECTIONS, '/contents/:contentId/edit'),
    },
    themes: {
      list: path(ROOTS_COLLECTIONS, '/themes'),
      create: path(ROOTS_COLLECTIONS, '/themes/create'),
      detail: path(ROOTS_COLLECTIONS, '/themes/:themeId'),
      edit: path(ROOTS_COLLECTIONS, '/themes/:themeId/edit'),
    },
    phases: {
      list: path(ROOTS_COLLECTIONS, '/phases'),
      create: path(ROOTS_COLLECTIONS, '/phases/create'),
      detail: path(ROOTS_COLLECTIONS, '/phases/:phaseId'),
      edit: path(ROOTS_COLLECTIONS, '/phases/:phaseId/edit'),
    },
    specialists: {
      list: path(ROOTS_COLLECTIONS, '/specialists'),
      create: path(ROOTS_COLLECTIONS, '/specialists/create'),
      detail: path(ROOTS_COLLECTIONS, '/specialists/:specialistId'),
      edit: path(ROOTS_COLLECTIONS, '/specialists/:specialistId/edit'),
    }
  }
};

export const PATH_CLIENTS = {
  root: '/',
  clients: {
    companies: {
      list: path(ROOTS_CLIENTS, '/companies'),
      create: path(ROOTS_CLIENTS, '/companies/create'),
      detail: path(ROOTS_CLIENTS, '/companies/:companyId'),
      edit: path(ROOTS_CLIENTS, '/companies/:companyId/edit'),
    },
    subscribers: {
      list: path(ROOTS_CLIENTS, '/subscribers'),
      create: path(ROOTS_CLIENTS, '/subscribers/create'),
      detail: path(ROOTS_CLIENTS, '/subscribers/:subscriberId'),
      edit: path(ROOTS_CLIENTS, '/subscribers/:subscriberId/edit'),
      dependent: path(ROOTS_CLIENTS, '/subscribers/:subscriberId/dependent'),
      import: path(ROOTS_CLIENTS, '/subscribers/import')
    },
    dependents: {
      list: path(ROOTS_CLIENTS, '/dependents'),
      detail: path(ROOTS_CLIENTS, '/dependents/:subscriberId'),
      edit: path(ROOTS_CLIENTS, '/dependents/:subscriberId/edit')
    }
  }
};

export const PATH_ATTENDANCES = {
  root: '/',
  attendances: {
    doctors: {
      list: path(ROOTS_ATTENDANCES, '/doctors'),
      create: path(ROOTS_ATTENDANCES, '/doctors/create'),
      detail: path(ROOTS_ATTENDANCES, '/doctors/:doctorId'),
      edit: path(ROOTS_ATTENDANCES, '/doctors/:doctorId/edit'),
    },
    nurses: {
      list: path(ROOTS_ATTENDANCES, '/nurses'),
      create: path(ROOTS_ATTENDANCES, '/nurses/create'),
      detail: path(ROOTS_ATTENDANCES, '/nurses/:nurseId'),
      edit: path(ROOTS_ATTENDANCES, '/nurses/:nurseId/edit'),
    },
    healthprofessionals: {
      list: path(ROOTS_ATTENDANCES, '/healthprofessionals'),
      create: path(ROOTS_ATTENDANCES, '/healthprofessionals/create'),
      detail: path(ROOTS_ATTENDANCES, '/healthprofessionals/:healthProfessionalId'),
      edit: path(ROOTS_ATTENDANCES, '/healthprofessionals/:healthProfessionalId/edit'),
    }
  },
};

export const PATH_AUTH = {
  root: '/',
  auth: {
    login: path(ROOTS_AUTH, '/login'),
    forgotPassword: path(ROOTS_AUTH, '/forgot-password')
  }
};

export const PATH_USERS = {
  root: '/',
  admin: {
    list: path(ROOTS_USERS, '/admin'),
    create: path(ROOTS_USERS, '/admin/create'),
    detail: path(ROOTS_USERS, '/admin/:userAdminId'),
    edit: path(ROOTS_USERS, '/admin/:userAdminId/edit'),
  }
};


export const PATH_NOTIFICATIONS = {
  root: '/',
  notifications: {
    list: path(ROOTS_NOTIFICATION, '/notification/list'),
    create: path(ROOTS_NOTIFICATION, '/notification/create'),
    detail: path(ROOTS_NOTIFICATION, '/notification/:notificationId'),
    edit: path(ROOTS_NOTIFICATION, '/notification/:notificationId/edit'),
  }
};



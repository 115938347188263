import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// components
import LoadingScreen from '../components/LoadingScreen';
import BlankLayout from "../layouts/BlankLayout";
import { GuestGuard } from "../guards/GuestGuard";
import { AuthGuard } from "../guards/AuthGuard";
import ContentMinDetail from 'pages/collections/ContentMinDetail';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen/>}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
    return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard" replace/>
    },
    {
      path: '/',
      element: <BlankLayout/>,
      children: [
        { element: <Navigate to="/public/contents" replace/>  },
        {
          path: '', children: [
            { path: ':contentId/:titleFmt', element: <PublicContent/> }
          ]
        }
      ]
    },
    {
      path: 'dashboard',
      element: <AuthGuard><DashboardLayout/></AuthGuard>,
      children: [
        { element: <Navigate to="/" replace/>  },
        {
          path: '', children: [
            { path: '', element: <Dashboard/> },
          ]
        }
      ]
    },
    {
      path: 'deeplinking',
      element: <AuthGuard><DashboardLayout/></AuthGuard>,
      children: [
        { element: <Navigate to="/" replace/>  },
        {
          path: '', children: [
            { path: '', element: <DeepLinking/> },
          ]
        }
      ]
    },
    {
      path: 'collections',
      element: <AuthGuard><DashboardLayout/></AuthGuard>,
      children: [
        { element: <Navigate to="/collections/contents" replace/>  },
        {
          path: 'contents', children: [
            { path: '', element: <ContentList/> },
            { path: 'create', element: <ContentSave/> },
            { path: ':contentId', element: <ContentDetail/> },
            { path: ':contentId/min', element: <ContentMinDetail/> },
            { path: ':contentId/edit', element: <ContentSave/> },
          ]
        }, {
          path: 'phases',
          children: [
            { path: '', element: <PhaseList/> },
            { path: 'create', element: <PhaseSave/> },
            { path: ':phaseId', element: <PhaseDetail/> },
            { path: ':phaseId/edit', element: <PhaseSave/> },
          ]
        }, {
          path: 'themes',
          children: [
            { path: '', element: <ThemeList/> },
            { path: 'create', element: <ThemeSave/> },
            { path: ':themeId', element: <ThemeDetail/> },
            { path: ':themeId/edit', element: <ThemeSave/> },
          ]
        }, {
          path: 'specialists',
          children: [
            { path: '', element: <SpecialistList/> },
            { path: 'create', element: <SpecialistSave/> },
            { path: ':specialistId', element: <SpecialistDetail/> },
            { path: ':specialistId/edit', element: <SpecialistSave/> },
          ]

        },
      ],
    },
    {
      path: 'clients',
      element: <AuthGuard><DashboardLayout/></AuthGuard>,
      children: [
        { element: <Navigate to="/clients/companies" replace/>  },
        {
          path: 'companies', children: [
            { path: '', element: <CompanyList/> },
            { path: 'create', element: <CompanySave/> },
            { path: ':companyId', element: <CompanyDetail/> },
            { path: ':companyId/edit', element: <CompanySave/> },
          ]
        },
        {
          path: 'subscribers', children: [
            { path: '', element: <SubscriberList/> },
            { path: 'create', element: <SubscriberSave/> },
            { path: ':subscriberId', element: <SubscriberDetail/> },
            { path: ':subscriberId/edit', element: <SubscriberSave/> },
            { path: ':subscriberId/dependent', element: <SubscriberSave dependent={true}/> },
            { path: 'import', element: <SubscriberImport/> }
          ]
        },
        {
          path: 'dependents', children: [
            { path: '', element: <SubscriberList dependent={true}/> },
            { path: ':subscriberId', element: <DependentDetail dependent={true}/> },
            { path: ':subscriberId/edit', element: <DependentSave/> },
          ]
        }]
    },
    {
      path: 'attendances',
      element: <AuthGuard><DashboardLayout/></AuthGuard>,
      children: [
        { element: <Navigate to="/attendances/doctors" replace/> },
        {
          path: 'doctors', children: [
            { path: '', element: <DoctorList/> },
            { path: 'create', element: <DoctorSave/> },
            { path: ':doctorId', element: <DoctorDetail/> },
            { path: ':doctorId/edit', element: <DoctorSave/> },
          ]
        },
        {
          path: 'nurses', children: [
            { path: '', element: <NurseList/> },
            { path: 'create', element: <NurseSave/> },
            { path: ':nurseId', element: <NurseDetail/> },
            { path: ':nurseId/edit', element: <NurseSave/> },
          ]
        },
        {
          path: 'healthprofessionals', children: [
            { path: '', element: <HealthProfessionalList/> },
            { path: 'create', element: <HealthProfessionalSave/> },
            { path: ':healthProfessionalId', element: <HealthProfessionalDetail/> },
            { path: ':healthProfessionalId/edit', element: <HealthProfessionalSave/> },
          ]
        }]
    },
    {
      path: 'users',
      element: <AuthGuard><DashboardLayout/></AuthGuard>,
      children: [
        { element: <Navigate to="/users/admin" replace/>  },
        {
          path: 'admin', children: [
            { path: '', element: <UserAdminList/> },
            { path: 'create', element: <UserAdminSave/> },
            { path: ':userAdminId', element: <UserAdminDetail/> },
            { path: ':userAdminId/edit', element: <UserAdminSave/> },
          ]
        }]
    },
    {
      path: 'auth',
      element: <GuestGuard><BlankLayout/></GuestGuard>,
      children: [
        { element: <Navigate to="/auth/login" replace/> },
        { path: 'login', element: <Login/> }
      ],
    },
    {
      path: 'notifications',
      element: <AuthGuard><DashboardLayout/></AuthGuard>,
      children: [
        { element: <Navigate to="/notifications/" replace/>  },
        {
          path: 'notification', children: [
            { path: 'list', element: <NotificationList/> },
            { path: 'create', element: <NotificationSave/> },
            { path: ':notificationId', element: <NotificationDetail/> },
            { path: ':notificationId/edit', element: <NotificationSave/> },
          ]
        }]
    },
    {
      path: '*',
      element: <LogoOnlyLayout/>,
      children: [
        { path: '404', element: <NotFound/> },
        { path: '*', element: <Navigate to="/404" replace/> },
      ],
    }
  ]);
}

// Mains
const Page500 = Loadable(lazy(() => import('pages/Page500')));
const NotFound = Loadable(lazy(() => import('pages/Page404')));

// Public
const PublicContent = Loadable(lazy(() => import('pages/public/content')));

//Dashboard
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));

//Deep linking
const DeepLinking = Loadable(lazy(() => import('pages/deeplinking/DeepLinkingList')));

//Notification
const NotificationList = Loadable(lazy(() => import('pages/notifications/NotificationList')));
const NotificationSave = Loadable(lazy(() => import('pages/notifications/NotificationSave')));
const NotificationDetail = Loadable(lazy(() => import('pages/notifications/NotificationDetail')));

// Content
const ContentList = Loadable(lazy(() => import('pages/collections/ContentList')));
const ContentSave = Loadable(lazy(() => import('pages/collections/ContentSave')));
const ContentDetail = Loadable(lazy(() => import('pages/collections/ContentDetail')));

// Phase
const PhaseList = Loadable(lazy(() => import('pages/collections/PhaseList')));
const PhaseSave = Loadable(lazy(() => import('pages/collections/PhaseSave')));
const PhaseDetail = Loadable(lazy(() => import('pages/collections/PhaseDetail')));

// Theme
const ThemeList = Loadable(lazy(() => import('pages/collections/ThemeList')));
const ThemeSave = Loadable(lazy(() => import('pages/collections/ThemeSave')));
const ThemeDetail = Loadable(lazy(() => import('pages/collections/ThemeDetail')));

// Specialist
const SpecialistList = Loadable(lazy(() => import('pages/collections/SpecialistList')));
const SpecialistSave = Loadable(lazy(() => import('pages/collections/SpecialistSave')));
const SpecialistDetail = Loadable(lazy(() => import('pages/collections/SpecialistDetail')));

// Company
const CompanyList = Loadable(lazy(() => import('pages/clients/CompanyList')));
const CompanySave = Loadable(lazy(() => import('pages/clients/CompanySave')));
const CompanyDetail = Loadable(lazy(() => import('pages/clients/CompanyDetail')));

// Subscriber
const SubscriberList = Loadable(lazy(() => import('pages/clients/SubscriberList')));
const SubscriberSave = Loadable(lazy(() => import('pages/clients/SubscriberSave')));
const SubscriberDetail = Loadable(lazy(() => import('pages/clients/SubscriberDetail')));
const SubscriberImport = Loadable(lazy(() => import('pages/clients/SubscriberImport')));
const DependentDetail = Loadable(lazy(() => import('pages/clients/DependentDetail')));
const DependentSave = Loadable(lazy(() => import('pages/clients/DependentSave')));

// Doctor
const DoctorList = Loadable(lazy(() => import('pages/attendances/DoctorList')));
const DoctorSave = Loadable(lazy(() => import('pages/attendances/DoctorSave')));
const DoctorDetail = Loadable(lazy(() => import('pages/attendances/DoctorDetail')));

// Nurse
const NurseList = Loadable(lazy(() => import('pages/attendances/NurseList')));
const NurseSave = Loadable(lazy(() => import('pages/attendances/NurseSave')));
const NurseDetail = Loadable(lazy(() => import('pages/attendances/NurseDetail')));

// Health Professional
const HealthProfessionalList = Loadable(lazy(() => import('pages/attendances/HealthProfessionalList')));
const HealthProfessionalSave = Loadable(lazy(() => import('pages/attendances/HealthProfessionalSave')));
const HealthProfessionalDetail = Loadable(lazy(() => import('pages/attendances/HealthProfessionalDetail')));

// Auth
const Login = Loadable(lazy(() => import('pages/auth/Login')));

// Admin
const UserAdminList = Loadable(lazy(() => import('pages/users/UserAdminList')));
const UserAdminSave = Loadable(lazy(() => import('pages/users/UserAdminSave')));
const UserAdminDetail = Loadable(lazy(() => import('pages/users/UserAdminDetail')));

import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import * as React from "react";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  schema?: 'purple' | 'white';
  style?: React.CSSProperties;
}

export default function Logo({ disabledLink = false, sx, schema = 'purple', style }: Props) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }} style={style}>
      {schema === 'purple' && <svg width="100%" height="100%" viewBox="0 0 300 285" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M285.078 284.169C293.314 284.169 299.99 277.501 299.99 269.276C299.99 261.05 293.314 254.382 285.078 254.382C276.843 254.382 270.167 261.05 270.167 269.276C270.167 277.501 276.843 284.169 285.078 284.169Z"
          fill="#7B47F6"/>
        <path
          d="M171.558 150.33C151.276 150.33 133.048 159.092 120.457 173.031C107.866 159.092 89.6382 150.33 69.3563 150.33C31.3481 150.33 0.537109 181.104 0.537109 219.067L0.686069 284.187H35.9659V219.106C35.9659 199.028 50.9167 182.749 69.3484 182.749C87.788 182.749 102.731 199.028 102.731 219.106V284.187H138.16V219.106C138.16 199.028 153.111 182.749 171.542 182.749C189.982 182.749 204.925 199.028 204.925 219.106V284.187H240.354V219.067C240.369 181.104 209.566 150.33 171.558 150.33Z"
          fill="#7B47F6"/>
        <path
          d="M68.7721 0C30.7875 0 0 30.751 0 68.6905C0 106.63 30.7875 137.381 68.7721 137.381C106.757 137.381 137.544 106.63 137.544 68.6905C137.544 30.751 106.757 0 68.7721 0ZM68.7721 105.064C50.3482 105.064 35.4131 88.7997 35.4131 68.7297C35.4131 48.6597 50.3482 32.3954 68.7721 32.3954C87.196 32.3954 102.131 48.6597 102.131 68.7297C102.131 88.7997 87.196 105.064 68.7721 105.064Z"
          fill="#7B47F6"/>
        <path
          d="M170.933 0C132.948 0 102.161 30.751 102.161 68.6905C102.161 106.63 132.948 137.381 170.933 137.381C208.917 137.381 239.705 106.63 239.705 68.6905C239.705 30.751 208.917 0 170.933 0ZM170.933 105.064C152.509 105.064 137.574 88.7997 137.574 68.7297C137.574 48.6597 152.509 32.3954 170.933 32.3954C189.357 32.3954 204.292 48.6597 204.292 68.7297C204.3 88.7997 189.364 105.064 170.933 105.064Z"
          fill="#7B47F6"/>
        <path
          d="M102.154 67.9388C102.342 50.5312 109.006 34.682 119.856 22.6776C107.273 8.74684 89.0529 0 68.7866 0V32.3954C86.6774 32.3954 101.283 47.7357 102.114 66.9991C102.13 67.3593 102.138 67.7195 102.146 68.0797L102.154 67.9388Z"
          fill="url(#paint0_linear_11_62)"/>
        <path d="M137.547 68.7281C137.547 88.7981 152.482 105.062 170.906 105.062V137.379C150.64 137.379 132.42 128.625 119.836 114.694C130.844 102.517 137.539 86.3863 137.539 68.689V68.7281H137.547Z"
              fill="url(#paint1_linear_11_62)"/>
        <defs>
          <linearGradient id="paint0_linear_11_62" x1="122.337" y1="66.2499" x2="88.3868" y2="19.2099" gradientUnits="userSpaceOnUse">
            <stop stopColor="#544576"/>
            <stop offset="0.9965" stopColor="#7B47F6"/>
          </linearGradient>
          <linearGradient id="paint1_linear_11_62" x1="114.836" y1="98.1692" x2="160.823" y2="104.207" gradientUnits="userSpaceOnUse">
            <stop stopColor="#544576"/>
            <stop offset="0.9965" stopColor="#7B47F6"/>
          </linearGradient>
        </defs>
      </svg>}
      {schema === 'white' && <svg width="100%" height="100%" viewBox="0 0 300 285" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M285.078 284.669C293.314 284.669 299.99 278 299.99 269.775C299.99 261.549 293.314 254.881 285.078 254.881C276.843 254.881 270.167 261.549 270.167 269.775C270.167 278 276.843 284.669 285.078 284.669Z"
          fill="white"/>
        <path
          d="M171.558 150.829C151.276 150.829 133.048 159.591 120.457 173.53C107.866 159.591 89.6382 150.829 69.3563 150.829C31.3481 150.829 0.537109 181.603 0.537109 219.566L0.686069 284.686H35.9659V219.606C35.9659 199.528 50.9167 183.248 69.3484 183.248C87.788 183.248 102.731 199.528 102.731 219.606V284.686H138.16V219.606C138.16 199.528 153.111 183.248 171.542 183.248C189.982 183.248 204.925 199.528 204.925 219.606V284.686H240.354V219.566C240.369 181.603 209.566 150.829 171.558 150.829Z"
          fill="white"/>
        <path
          d="M68.7721 0.499268C30.7875 0.499268 0 31.2503 0 69.1898C0 107.129 30.7875 137.88 68.7721 137.88C106.757 137.88 137.544 107.129 137.544 69.1898C137.544 31.2503 106.757 0.499268 68.7721 0.499268ZM68.7721 105.563C50.3482 105.563 35.4131 89.2989 35.4131 69.229C35.4131 49.159 50.3482 32.8947 68.7721 32.8947C87.196 32.8947 102.131 49.159 102.131 69.229C102.131 89.2989 87.196 105.563 68.7721 105.563Z"
          fill="white"/>
        <path
          d="M170.933 0.499268C132.948 0.499268 102.161 31.2503 102.161 69.1898C102.161 107.129 132.948 137.88 170.933 137.88C208.917 137.88 239.705 107.129 239.705 69.1898C239.705 31.2503 208.917 0.499268 170.933 0.499268ZM170.933 105.563C152.509 105.563 137.574 89.2989 137.574 69.229C137.574 49.159 152.509 32.8947 170.933 32.8947C189.357 32.8947 204.292 49.159 204.292 69.229C204.3 89.2989 189.364 105.563 170.933 105.563Z"
          fill="white"/>
        <path
          d="M102.154 68.438C102.342 51.0305 109.006 35.1812 119.856 23.1768C107.273 9.2461 89.0529 0.499268 68.7866 0.499268V32.8947C86.6774 32.8947 101.283 48.2349 102.114 67.4984C102.13 67.8586 102.138 68.2188 102.146 68.579L102.154 68.438Z"
          fill="url(#paint0_linear_107_3)"/>
        <path
          d="M137.547 69.2274C137.547 89.2974 152.482 105.562 170.906 105.562V137.879C150.64 137.879 132.42 129.124 119.836 115.193C130.844 103.017 137.539 86.8855 137.539 69.1882V69.2274H137.547Z"
          fill="url(#paint1_linear_107_3)"/>
        <defs>
          <linearGradient id="paint0_linear_107_3" x1="122.337" y1="66.7492" x2="88.3868" y2="19.7092" gradientUnits="userSpaceOnUse">
            <stop stopColor="#BBBDBF"/>
            <stop offset="0.9965" stopColor="white"/>
          </linearGradient>
          <linearGradient id="paint1_linear_107_3" x1="114.836" y1="98.6685" x2="160.823" y2="104.707" gradientUnits="userSpaceOnUse">
            <stop stopColor="#BBBDBF"/>
            <stop offset="0.9965" stopColor="white"/>
          </linearGradient>
        </defs>
      </svg>}
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

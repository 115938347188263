import { useDispatch } from "react-redux";
import { userReducerMeState, userReducerState } from "../reducers/userReducer";

export function useUserAction() {
  const dispatch = useDispatch();

  return {
    fetchUserData: (userData: userReducerMeState) => {
      dispatch({
        type: "FETCH_USER_DATA",
        data: {
          name: userData?.name ?? "",
          email: userData?.email,
          isAdmin : userData?.isAdmin
        }
      })
    }
  }
}
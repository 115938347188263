import React, { Fragment } from 'react'
import ContentLoader from 'react-content-loader'

export function ReadonlyFieldLoading(props: any) {
  const { rows = 1 } = props

  return (
    <ContentLoader height={15} width={"100%"} style={{ paddingTop: 0, paddingBottom: 0 }} {...props}>
      {new Array(rows).fill(' ').map((el, index) => {
        return (
          <Fragment key={index}>
            <rect
              x="0"
              y={"0"}
              rx="5"
              ry="5"
              width="100%"
              height="100%"
            />
          </Fragment>
        )
      })}
    </ContentLoader>
  )
}
// @mui
import { FormControlLabel, Radio, RadioGroup as RadioContainer, Typography } from '@mui/material';

// components
import { View } from "components/View";

// types
import { KeyValue } from "types/KeyValue";
import { ReadonlyField } from "../ReadonlyField";

type RadioInputProps = {
  label?: string;
  selected?: KeyValue;
  values: KeyValue[];
  onChange: (value?: KeyValue) => void;
  row?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  loading?: boolean;
};

export function RadioInput(props: RadioInputProps) {
  const { label, selected, values, onChange, row = true, disabled = false, readonly = false, loading = false } = props;

  return (readonly || loading
      ? <ReadonlyField label={label} values={!!selected?.value ? [selected.value] : undefined} loading={loading}/>
      : <View>
        {label && <Typography variant="subtitle2" gutterBottom>
          {label}
        </Typography>}
        <RadioContainer
          row={row}
          aria-label={label}
          name={label}
          onChange={(e) => onChange(values.find(a => a.key === e.target.value))}
          value={selected?.key ?? ''}
          defaultValue={''}>
          {values.map((item, index) => (
            <FormControlLabel
              sx={{ pr: 2 }}
              disabled={disabled}
              key={index}
              label={item.value ?? ""}
              value={item.key} control={<Radio/>}/>
          ))}
        </RadioContainer>
      </View>
  )
}

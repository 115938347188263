import { FormControlLabel, FormGroup, Switch as MUISwitch } from '@mui/material';

// ----------------------------------------------------------------------

type SwitchProps = {
  disabled?: boolean,
  value: boolean,
  labelPosition?: 'top' | 'start' | 'bottom' | 'end',
  label?: string,
  text?: string,
  onChange: (value: boolean) => void,
}

export function Switch({ text = 'text', label = 'label', disabled = false, onChange, value, labelPosition = 'end' }: SwitchProps) {
  return (
    <FormGroup row>
      <FormControlLabel
        label={label}
        labelPlacement={labelPosition}
        control={<MUISwitch checked={value} onChange={(e) => onChange(e.target.checked)} sx={{ mr: 2 }} disabled={disabled}/>}/>
    </FormGroup>
  );
}

import * as React from 'react';

import { View } from "components/View";

export type  Props = {
  size?: number,
  vertical?: boolean
}

export function Spacer(props: Props) {
  const { size, vertical } = props;
  const spacerSize = 24 * (size ?? 1);

  return vertical
    ? (<View style={{ width: spacerSize }}/>)
    : (<View style={{ height: spacerSize }}/>);
}
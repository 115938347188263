import React, { useState } from 'react';
import TextFieldDefault from '@mui/material/TextField';
import { AsYouType } from "libphonenumber-js";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { ReadonlyField } from "../ReadonlyField";

export type TextInputProps = TextFieldProps & {
  label?: string;
  value?: string | number;
  secureTextEntry?: boolean,
  maxLength?: number,
  helperText?: string | boolean,
  readonly?: boolean;
  loading?: boolean;
  required?: boolean;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters',
  mask?: 'phone' | 'date' | 'number' | 'cpf' | 'credit-card-number' | 'credit-card-valid-thru' | 'username' | 'email' | 'instagram' | 'time' | 'cnpj' | 'datetime',
  onChangeValue?: (value: string) => void,
}

const defaultProps: TextInputProps = {
  autoCapitalize: "none",
  multiline: false,
  fullWidth: false,
  loading: false
};

export const TextInput = (props: TextInputProps) => {
  props = { ...defaultProps, ...props };

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<any>(props.helperText);

  const masks = {
    "username": (value: string) => (value || '').replace(/\s/g, ''),
    "email": (value: string) => (value || '').replace(/\s/g, ''),
    "instagram": (value: string) => (value || '').replace(/([\s@#-*])/gi, ''),
    "date": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{2})(\d{1,2})?(\d{1,4})?/, '$1/$2/$3').replace(/\D+$/, '').slice(0, 10),
    "crm": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{3})(\d{1,3})?/, '$1/$2').replace(/\D+$/, '').slice(0, 10),
    "time": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{2})?(\d{1,2})?/, '$1:$2').replace(/\D+$/, '').slice(0, 5),
    "phone": (value: string) => value,
    "number": (value: string) => (value || '').replace(/\D+/g, ''),
    "cpf": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{3})(\d{1,3})(\d{1,3})?(\d{1,2})?/, '$1.$2.$3-$4').replace(/\D+$/, '').slice(0, 14),
    "cnpj": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{2})(\d{1,3})(\d{1,3})?(\d{1,4})?(\d{1,2})?/, '$1.$2.$3/$4-$5').replace(/\D+$/, '').slice(0, 18),
    "credit-card-number": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{4})(\d{1,4})(\d{1,4})?(\d{1,4})?/, '$1 $2 $3 $4').replace(/\D+$/, '').slice(0, 19),
    "credit-card-valid-thru": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{2})(\d{1,4})?/, '$1/$2').replace(/\D+$/, '').slice(0, 7),
    "datetime": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{2})(\d{1,2})?(\d{1,4})(\d{2})?(\d{1,2})?/, '$1/$2/$3 $4:$5').replace(/\D+$/, '').slice(0, 16)
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target?.value ?? '';

    if (props.maxLength) {
      if (value.length > props.maxLength) {
        value = value.slice(0, props.maxLength);
      }
      setHelperText(value.length + "/" + props.maxLength);
    }

    if (props.mask) {
      if (props.mask === "phone") {
        value = value.replace(/\D+/g, '');
        value = value.slice(0, 11);
        value = new AsYouType('BR').input(value);
      } else if (props.mask === "number") {
        value = masks.number(value)
        value = value.slice(0, props.maxLength);
        setHelperText("");
      } else value = masks[props.mask](value);
    }

    event.target.value = value;

    if (props.onChangeValue) props.onChangeValue(value);
    if (props.onChange) props.onChange(event);
  };

  const renderIcon = () => {
    if (props.secureTextEntry) {
      return (
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
          edge="end"
        >
          {showPassword ? <Visibility/> : <VisibilityOff/>}
        </IconButton>
      )
    }
    return null;
  }

  return (props.readonly || props.loading
      ? <ReadonlyField label={props.label} value={props?.value?.toString() ?? ''} loading={props.loading}/>
      : <TextFieldDefault
        {...props}
        fullWidth={props.fullWidth}
        helperText={props.helperText || helperText}
        InputProps={{
          endAdornment: !!props.InputProps?.endAdornment ? props.InputProps.endAdornment : (!!renderIcon() && <InputAdornment position="end">{renderIcon()}</InputAdornment>),
          autoCapitalize: props.autoCapitalize,
        }}
        multiline={props.multiline}
        type={props.secureTextEntry && !showPassword ? 'password' : 'text'}
        variant="outlined"
        label={props.label}
        style={{ ...props.style, opacity: props.disabled ? 0.5 : 1 }}
        value={props.value ? props.value : ""}
        onChange={onChange}
      />
  )
};

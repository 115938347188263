import axios from "axios";
import { backofficeApiConfig } from "config";
import { oAuthModel } from "./oAuthModel";

export const useOAuthApi = () => ({
  login: async (username: string, password: string): Promise<oAuthModel> => {
    const data = new URLSearchParams();
    data.append("client_id", backofficeApiConfig.oAuthClientId);
    data.append("client_secret", backofficeApiConfig.oAuthClientSecret);
    data.append("grant_type", "password");
    data.append("username", username);
    data.append("password", password);
    return axios.post(backofficeApiConfig.oAuthTokenUrl, data).then(resp => {
      localStorage.setItem('accessToken', resp.data.access_token);
      return resp.data;
    });
  }
})

import React from "react";

// material
import { Autocomplete as AutocompleteDefault, TextField } from '@mui/material';

// types
import { KeyValue } from "types/KeyValue";
import { ReadonlyField } from "components/ReadonlyField";

type AutocompleteProps = {
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  loading?: boolean;
  multiple?: boolean;
  value?: KeyValue;
  values?: KeyValue[];
  options: KeyValue[];
  helperText?: React.ReactNode;
  error?: boolean;
  onChange: (value: (KeyValue | string) | (KeyValue | string)[] | undefined) => void;

}
export default function Autocomplete(
  {
    label,
    placeholder,
    options,
    values,
    value,
    multiple = false,
    onChange,
    disabled = false,
    readonly = false,
    helperText,
    required,
    loading = false,
    ...rest
  }: AutocompleteProps) {

  return (
    readonly || loading
      ? multiple
        ? <ReadonlyField label={label} values={values?.map(a => a?.value ?? '')} loading={loading}/>
        : <ReadonlyField label={label} values={[value?.value ?? '']} loading={loading}/>
      : multiple
        ? <AutocompleteDefault
          fullWidth
          isOptionEqualToValue={(o, v) => (o.key === v.key)}
          filterSelectedOptions
          disabled={disabled}
          multiple={multiple}
          value={values ?? []}
          noOptionsText={"Nenhum item encontrado"}
          options={options}
          getOptionLabel={(option) => option?.value ?? ''}
          onChange={(e, selected) => onChange(selected ?? undefined)}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={helperText}
              label={required ? label + " *" : label}
              placeholder={placeholder}/>
          )}
        />
        : <AutocompleteDefault
          fullWidth
          isOptionEqualToValue={(o, v) => (o.key === v.key)}
          filterSelectedOptions
          disabled={disabled}
          value={value ?? { key: `none`, value: '' }}
          noOptionsText={"Nenhum item encontrado"}
          options={[{ key: `none`, value: '' }, ...options]}
          getOptionLabel={(option) => option?.value ?? ''}
          onChange={(e, selected) => onChange(selected ?? undefined)}
          renderInput={(params) => (
            <TextField
              {...params}
              {...rest}
              helperText={helperText}
              label={required ? label + " *" : label}
              placeholder={placeholder}/>
          )}
        />
  );
}
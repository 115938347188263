import React from 'react'
import ContentLoader from 'react-content-loader'

export function EditorLoading(props: any) {
  return <ContentLoader
    width={'100%'}
    height="200"
    {...props}
  >
    <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%"/>
  </ContentLoader>
}

// components
import SvgIconStyle from '../../../components/SvgIconStyle';

import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import DeepLinkingIcon from '@mui/icons-material/LinkTwoTone';
import FeedTwoToneIcon from '@mui/icons-material/FeedTwoTone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CommentBankTwoToneIcon from '@mui/icons-material/CommentBankTwoTone';
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import RecordVoiceOverTwoToneIcon from '@mui/icons-material/RecordVoiceOverTwoTone';
import ContactMailTwoToneIcon from '@mui/icons-material/ContactMailTwoTone';
import MedicalServicesTwoToneIcon from '@mui/icons-material/MedicalServicesTwoTone';
import BloodtypeTwoToneIcon from '@mui/icons-material/BloodtypeTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ConfirmationNumberTwoToneIcon from '@mui/icons-material/ConfirmationNumberTwoTone';
import MasksTwoToneIcon from '@mui/icons-material/MasksTwoTone';
import { PATH_COLLECTIONS, PATH_ATTENDANCES, PATH_CLIENTS, PATH_USERS, PATH_NOTIFICATIONS, PATH_DASHBOARD, PATH_DEEPLINKING } from "../../../routes/paths";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }}/>
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const navConfig = [
  {
    subheader: 'Painel',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.panel.dashboard, icon: <DashboardTwoToneIcon/> },
      { title: 'Deep Linking', path: PATH_DEEPLINKING.panel.deeplinking, icon: <DeepLinkingIcon/> },
    ]
  },
  {
    subheader: 'Acervo',
    items: [
      { title: 'Conteúdos', path: PATH_COLLECTIONS.collections.contents.list, icon: <FeedTwoToneIcon/> },
      { title: 'Temas', path: PATH_COLLECTIONS.collections.themes.list, icon: <CommentBankTwoToneIcon/>, },
      { title: 'Fases', path: PATH_COLLECTIONS.collections.phases.list, icon: <CalendarTodayTwoToneIcon/>, },
      { title: 'Especialistas', path: PATH_COLLECTIONS.collections.specialists.list, icon: <RecordVoiceOverTwoToneIcon/>, }
    ],
  },
  {
    subheader: 'Cliente',
    items: [
      { title: 'Empresas', path: PATH_CLIENTS.clients.companies.list, icon: <BusinessTwoToneIcon/> },
      { title: 'Assinantes', path: PATH_CLIENTS.clients.subscribers.list, icon: <PeopleAltTwoToneIcon/> },
      { title: 'Convidados', path: PATH_CLIENTS.clients.dependents.list, icon: <ContactMailTwoToneIcon/> },
    ],
  },
  /*{
    subheader: 'Notificação',
    items: [
      { title: 'Notificações', path: PATH_NOTIFICATIONS.notifications.list, icon: <NotificationsActiveIcon/> },
    ],
  },*/
  {
    subheader: 'Atendimento',
    items: [
      { title: 'Médicos', path: PATH_ATTENDANCES.attendances.doctors.list, icon: <MedicalServicesTwoToneIcon/> },
      { title: 'Enfermeiros', path: PATH_ATTENDANCES.attendances.nurses.list, icon: <BloodtypeTwoToneIcon/> },
      { title: 'Profissionais de saúde', path: PATH_ATTENDANCES.attendances.healthprofessionals.list, icon: <MasksTwoToneIcon/> },
    ],
  }, {
    subheader: 'Usuários',
    items: [
      { title: 'Administradores', path: PATH_USERS.admin.list, icon: <VpnKeyTwoToneIcon/> },
    ],
  }
];

export default navConfig;

export type userReducerState = {
  email?: string;
  name?: string;
  isAdmin?: boolean;
};
export type userReducerMeState = {
  email?: string;
  name?: string;
  isAdmin?: boolean;

};

const initialState: userReducerState = {
  name: undefined,
  email: undefined,
  isAdmin: undefined,
};

const userReducer = (
  state: userReducerState = initialState,
  action: any
): userReducerState => {
  switch (action.type) {
    case "FETCH_USER_DATA": {
      return {
        ...state,
        ...action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;

import { useState } from 'react';

import { useParams } from 'react-router';

// material
import { Container, Stack, IconButton, Tooltip } from '@mui/material';

// components
import Page from 'components/Page';
import { HeaderBreadcrumbs } from "components/HeaderBreadcrumbs";
import { ContentForm } from './components/ContentForm';
import { TextInput } from 'components/TextInput';
import { Spacer } from 'components/Spacer';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

// routes
import { PATH_COLLECTIONS, PATH_PUBLIC } from 'routes/paths';

import { backofficeApiConfig } from "config";

export default function ContentDetail() {
  const { contentId } = useParams();
  const [copied, setCopy] = useState<boolean>(false);

  const handleCopyToClipboard = async () => {
    navigator.clipboard.writeText(backofficeApiConfig.backofficeAdminURL + PATH_PUBLIC.public.contents.detail.replace(':contentId', contentId ?? ''));
    setCopy(!copied);

    if (!copied === true) {
      const timer = setTimeout(() => { 
        handleCopyToClipboard();
      }, 2000);
      clearTimeout(timer);
    }
  }

  return (<Page title="Bloom | Conteúdos">
      <Container maxWidth={'lg'}>
        <HeaderBreadcrumbs
          heading={'Detalhe conteúdo'}
          links={[{ name: 'Informações amigáveis' }]}
          actions={[{ label: "Voltar", path: PATH_COLLECTIONS.collections.contents.list }]}
        />
        <Spacer/>
        <ContentForm mindetail contentId={contentId} readonly/>
      </Container>
    </Page>
  );
}
import { useState, ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import { useBackofficeApi } from 'apis/backoffice';
import { useUserAction } from 'redux/actions/userAction';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export function AuthGuard({ children }: AuthGuardProps) {
  const { fetchUserData } = useUserAction();

  const { isAuthenticated, isInitialized } = useAuth();
  const backofficeApi = useBackofficeApi()
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);


  useEffect(() => {
    (async () => {
      if (isAuthenticated) await backofficeApi.api.userMeList().then((resp) => {
        const isAdmin = resp.data?.data?.hasOwnProperty('userAdminId') ? true : false;
        fetchUserData({
          name: resp.data.data?.name ?? "Pessoa",
          email: resp.data.data?.email ?? "Usuário",
          isAdmin: isAdmin
        }
      );

      })
    })();
  }, [isAuthenticated]);

  if (!isInitialized) {
    return <LoadingScreen/>;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login/>;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation}/>;
  }

  return <>{children}</>;
}

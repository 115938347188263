// @mui
import { Box, Stack, Typography } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------

type BlockContentProps = {
  accept?: string[] | string;
  maxSize?: number;
}

export default function BlockContent(props: BlockContentProps) {

  const { accept, maxSize } = props;

  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <UploadIllustration sx={{ width: 100 }}/>

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          Arraste e solte o arquivo aqui
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Formatos: {accept ? (typeof accept === "string" ? accept : accept.join(", ")) : "Todos formatos"}
        </Typography>

        {!!maxSize && <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Tamanho: {maxSize} bytes
        </Typography>}
      </Box>
    </Stack>
  );
}

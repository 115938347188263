import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar } from '@mui/material';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { useAuthAction } from "../../../redux/actions/authAction";
import useAuth from "../../../hooks/useAuth";
import { useSelector } from 'react-redux';
import { userReducerState } from 'redux/reducers/userReducer';
import { getFirstCharacter } from 'utils/firstCharacter';

export default function AccountPopover() {
  const { name, email, isAdmin } = useSelector((state: { user: userReducerState }) => state.user);
  const { logout } = useAuth();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar>{getFirstCharacter(name)}</Avatar>
      </IconButtonAnimate>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ p: 0, mt: 1.5, ml: 0.75, '& .MuiMenuItem-root': { typography: 'body2', borderRadius: 0.75, }, }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
          {name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {isAdmin ? 'Administrador' :  'Usuário'}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }}/>
        <MenuItem sx={{ m: 1 }} onClick={logout}>Sair</MenuItem>
      </MenuPopover>
    </>
  );
}

// material
import { Box, Link, Stack, Typography } from '@mui/material';

import { MBreadcrumbs } from '../@material-extend';
import { MBreadcrumbsProps } from '../@material-extend/MBreadcrumbs';
import { Button } from "../Button";

interface HeaderBreadcrumbsProps extends MBreadcrumbsProps {
  actions?: {
    label: string,
    path?: string,
    onClick?: () => void
  }[];
  functions?: {
    label: string,
    path?: string,
    onClick?: () => void
  }[];
  heading: string;
  moreLink?: string | string[];
}

export function HeaderBreadcrumbs(
  { links, actions, functions, heading, moreLink = '' || [], sx, ...other }: HeaderBreadcrumbsProps) {
  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {heading}
          </Typography>
          <MBreadcrumbs links={links} {...other} />
        </Box>
        {actions && <Box sx={{ flexShrink: 0 }}>
          <Stack direction="row" spacing={1}>
            {actions.map((action, index) => <Button key={index} label={action.label} path={action.path} onClick={action.onClick}/>)}
          </Stack>
        </Box>}
      </Box>
      <Box sx={{ mt: 2 }}>
        {typeof moreLink === 'string' ? (
          <Link href={moreLink} target="_blank" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}

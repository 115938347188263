import { SettingsValueProps } from './components/settings/type';

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'purple',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const backofficeApiConfig = {
  backofficeApiURL: `${process.env.REACT_APP_BACKOFFICEAPI_URL}`,
  backofficeAdminURL: `${process.env.REACT_APP_BACKOFFICEADMIN_URL}`,
  oAuthTokenUrl: `${process.env.REACT_APP_OAUTH_TOKEN_URL}`,
  oAuthClientId: `${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
  oAuthClientSecret: `${process.env.REACT_APP_OAUTH_CLIENT_SECRET}`
}

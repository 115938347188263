import { KeyValue } from "types/KeyValue";

export const ENUM_DESCRIPTIONS = {
  Audio: 'Áudio',
  Video: 'Vídeo',
  Article: 'Artigo',

  Draft: 'Rascunho',
  Published: 'Publicado',

  Planning: 'Estou planejando ter filhos',
  Pregnancy: 'Gravidez',
  Parenthood: 'Tenho filhos',
  NoChildren: 'Não tenho filhos',

  Male: 'Masculino',
  Female: 'Feminino',
  Other: 'Outros',

  Active: 'Ativo',
  Inactive: 'Inativo',

  Review: 'Em análise',
  WaitingApproval: 'Esperando aprovação',
  ReadyToSend: 'Preparado pra enviar',
  Sent: 'Enviado',
  Canceled: 'Cancelado'

}

export default function mappingEnumToKeyValue(enums: any): KeyValue[] {
  let keyValues: KeyValue[] = [];
  for (let item of enums) {
    if (ENUM_DESCRIPTIONS.hasOwnProperty(item)) {
      // @ts-ignore
      keyValues.push({ key: item, value: ENUM_DESCRIPTIONS[item] })
    } else {
      keyValues.push({ key: item, value: item })
    }
  }
  return keyValues;
}

export function mappingToKeyValue(list: any): KeyValue[] {
  let keyValues: KeyValue[] = [];
  for (let item of list) {
    keyValues.push({ key: item.id, value: item.name });
  }
  return keyValues;
}

export function mEnumToValue(value: any): string {
  if (!!value && ENUM_DESCRIPTIONS.hasOwnProperty(value)) {
    // @ts-ignore
    return ENUM_DESCRIPTIONS[value];
  } else {
    return value
  }
}


// @ts-ignore
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/material/styles';
import { Box, BoxProps, Typography } from '@mui/material';
import { View } from 'components/View';
import * as React from "react";
import { EditorLoading } from "./EditorLoading";

const RootStyle = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily
  },
  '& .ql-toolbar.ql-snow': {
    border: 0
  },
  '& .ql-editor': {
    minHeight: 200,
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.disabled
    },
    '& pre.ql-syntax': {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900]
    }
  }
}));

const RootStyleReadonly = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily
  },
  '& .ql-toolbar.ql-snow': {
    border: 0,
    display: 'none'
  },
  '& .ql-editor': {
    minHeight: 200,
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.disabled
    },
    '& pre.ql-syntax': {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900]
    }
  }
}));

// ----------------------------------------------------------------------

interface EditorProps {
  label?: string;
  id?: string;
  required?: boolean;
  readonly?: boolean;
  loading?: boolean;
  error?: boolean;
  simple?: boolean;
  sx?: BoxProps;
  disabled?: boolean;
  value?: string;
  helperText?: string | boolean;
  onChange?: (content: string) => void;
}

export default function Editor(props: EditorProps) {
  const { helperText = '', label, id = 'minimal-quill', required, error, value, onChange, simple = true, sx, readonly, loading = false, ...other } = props;

  return (<View>
    {label && <Typography variant="subtitle2" gutterBottom>
      {required ? label + "*" : label}
    </Typography>}
    {!readonly
      ? (!loading
        ? <RootStyle sx={{ ...(error && { border: (theme) => `solid 1px ${theme.palette.error.main}` }), ...sx }}>
          <ReactQuill
            theme="snow"
            value={value}
            onChange={onChange}
            readOnly={readonly}
            {...other}
          />
        </RootStyle>
        : <EditorLoading />)
      : (!loading
        ? <RootStyleReadonly sx={{ ...(error && { border: (theme) => `solid 1px ${theme.palette.error.main}` }), ...sx }}>
          <ReactQuill
            theme="snow"
            value={value}
            onChange={onChange}
            readOnly={readonly}
            {...other}
          />

        </RootStyleReadonly>
        : <EditorLoading />)
    }
      {!!helperText && <Typography variant='overline' >{helperText}</Typography>}


  </View>
  );
}

import { ReactNode } from 'react';
import { useSelector } from "react-redux";
import { AuthReducerState } from "../redux/reducers/authReducer";
import { Navigate } from "react-router-dom";
import { PATH_COLLECTIONS } from "../routes/paths";

type AuthGuardProps = {
  children: ReactNode;
};

export function GuestGuard({ children }: AuthGuardProps) {
  const { loggedIn } = useSelector((state: { auth: AuthReducerState }) => state?.auth);

  if (!loggedIn) return <>{children}</>
  else return <Navigate to={PATH_COLLECTIONS.collections.contents.list}/>;
}

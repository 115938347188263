// @mui
import { Chip, Divider, Stack, Typography } from '@mui/material';

// components
import { View } from "components/View";

// types
import { Spacer } from "../Spacer";
import { ReadonlyFieldLoading } from "./ReadonlyFieldLoading";

type ReadonlyFieldProps = {
  label?: string;
  value?: string;
  values?: string[];
  loading?: boolean;
};

export function ReadonlyField(props: ReadonlyFieldProps) {
  const { label, value, values, loading = false } = props;

  const renderValues = () => {
    if (!!value) {
      if (value.indexOf('.mp4') > -1 || value.indexOf('.avi') > -1) {
        return (<video controls style={{ width: '100%', borderRadius: '10px' }}>
          <source src={value} type="video/mp4"/>
        </video>);
      } else if (value.indexOf('.png') > -1 || value.indexOf('.jpg') > -1 || value.indexOf('.gif') > -1 || value.indexOf('.jpeg') > -1) {
        return (<img style={{ borderRadius: '10px', width: '100%' }} src={value}/>);
      } else if (value.indexOf('.mp3') > -1) {
        return (<audio controls style={{ width: '100%', marginTop: '16px', }}>
          <source src={value} type="audio/mpeg"/>
        </audio>);
      } else
        return <Typography variant="body1">{value}</Typography>;
    } else if (!!values && values.length > 0) {
      return <Stack direction={"row"} spacing={2} flexWrap={"wrap"} style={{ marginTop: '-10px' }}>{values.map((val, index) => {
        return (<Chip key={index} size="small" label={val} style={{ margin: '10px 16px 0 0' }}/>)
      })}</Stack>;
    } else return <Typography variant="body1">--</Typography>;
  }

  const renderLoading = () => {
    return <ReadonlyFieldLoading/>
  }

  return (<View>
      <Typography variant="subtitle2" gutterBottom>
        {label}
      </Typography>
      {!loading ? renderValues() : renderLoading()}
      <Spacer/>
      <Divider/>
    </View>
  )
}

import * as React from "react";
import { DetailedHTMLProps } from "react";

export type ViewProps = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  style?: React.CSSProperties,
  className?: string,
  children?: React.ReactNode,
  divRef?: (element: HTMLElement | null) => any;
};

export function View(props: ViewProps) {
  const { style, className, divRef, ...otherProps } = props;
  return <div style={style} className={className} {...otherProps} ref={divRef}/>;
}

import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Typography(theme: Theme) {
  return {

    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2)
        },
        gutterBottom: {
          marginBottom: theme.spacing(1)
        },
        overline :{
        textTransform: 'none',
        fontWeight: '400',
        lineHeight: '1.5',
        fontSize: 13,
        textAlign: 'left',
        marginRight: '14px',
        marginBottom: 0,
        marginLeft: '14px',
        marginTop: '8px',
        color:'#FF4842'
        }
      }
    }
  };
}

import React, { useCallback, useState } from 'react';

// components
import { Typography } from "@mui/material";
import { View } from "../View";
import { ReadonlyField } from "../ReadonlyField";
import { RenderFile } from "./type";
import { UploadSingleFile } from "./UploadSingleFile";
import { useBackofficeApi } from "apis/backoffice";
import { MediaTypeEnum } from "apis/backoffice/BackofficeApi";
import { LinearProgressWithLabel } from "./LinearProgressWithLabel";
import { Spacer } from "../Spacer";

type Props = {
  label?: string;
  required?: boolean;
  singleFile?: boolean;
  readonly?: boolean;
  loading?: boolean;
  file?: RenderFile;
  files?: (RenderFile)[];
  accept: 'video' | 'image' | 'audio' | 'spreadsheet';
  mediaType?: MediaTypeEnum
  maxSize?: number;
  helperText?: string | boolean;
  onFileChange?: (file?: RenderFile) => void;
  onFilesChange?: (file?: RenderFile) => void;
};

export function Upload(props: Props) {
  const backofficeApi = useBackofficeApi()
  const {helperText='', readonly = false, required = false, label, accept, maxSize, files, file, singleFile, loading = false, mediaType = MediaTypeEnum.Image, onFileChange, onFilesChange } = props;

  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const handleDropSingleFile = useCallback((acceptedFiles) => {
    const acceptedFile = acceptedFiles[0];
    if (acceptedFile && onFileChange) {
      setLoadingFile(true);
      onFileChange(undefined);
      const formData = new FormData();
      formData.append("file", acceptedFile);
      // @ts-ignore
      backofficeApi.api.mediaUploadCreate({ type: mediaType }, formData, setProgress).then(resp => {
        onFileChange({ preview: URL.createObjectURL(acceptedFile), mediaId: resp?.data?.data?.mediaId ?? undefined });
        setLoadingFile(false);
        setProgress(0);
      });
    }
  }, []);

  let acceptFormat: string = "*.*";
  if (accept === "video") {
    acceptFormat = ".mp4, .avi, video/mp4, video/avi";
  } else if (accept === "image") {
    acceptFormat = ".png, .gif, .jpeg, .jpg"
  } else if (accept === "audio") {
    acceptFormat = ".mp3"
  } else if (accept === "spreadsheet") {
    acceptFormat = ".xlsx"
  }

  return (readonly || loading
    ? <ReadonlyField label={label} value={file?.preview ?? undefined} loading={loading}/>
    : <View>{label && <Typography variant="subtitle2" gutterBottom>
      {`${label}${!!required ? '*' : ''}`}
    </Typography>}
      {loadingFile && (<><LinearProgressWithLabel value={progress}/><Spacer size={0.5}/></>)}
      <UploadSingleFile
        acceptFormat={accept}
        accept={acceptFormat}
        maxSize={maxSize}
        file={file}
        onDrop={handleDropSingleFile}
        helperText={helperText}
      />
    </View>)
}
